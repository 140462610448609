import React, { useEffect } from 'react';

const TARGET =
  'https://gemfinance.zendesk.com/hc/en-nz/articles/31929777177233-Gem-CreditLine-Changes-FAQs';

function ZendeskCreditCardChangeRedirectPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace(TARGET);
    }
  }, []);

  return <></>;
}

export default ZendeskCreditCardChangeRedirectPage;